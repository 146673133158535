/**
 * enum tools
 */

export const USER_INFO = {
  meeting: "",
  meetingPassword: "",
  meetingName: "",
  muteVideo: false,
  muteAudio: false,
};


export const DEFAULT_DEVICES = {
  audioInputList: [],
  audioOutputList: [],
  videoInList: [],
};