/**
 * import element ui lib
 */

import Vue from "vue";
import Element from "element-ui";

import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element);
